<template>
	<div class="d-flex min-vh-100 align-items-center">
		<CContainer>
			<CRow v-if="provider">
				<CCol>
					<div class="register-social-form">
						<CForm @submit.prevent="handleRegisterSocial">
							<header>
								<img
									:src="icon.url"
									:alt="icon.alt"
									loading="lazy"
									class="register-social-form-icon"
								>

								<p class="register-social-form-desc">
									{{ desc }}
								</p>

								<h3 class="register-social-form-heading">
									{{ title }}
								</h3>
							</header>

							<CInput
								v-model.trim="staffId"
								disabled
								class="register-social-form-input"
								placeholder="Staff ID"
								autocomplete="number"
								data-test-id="input-staff-id"
							/>

							<CInput
								v-model.trim="firstName"
								disabled
								class="register-social-form-input"
								placeholder="First Name"
								autocomplete="first name"
								data-test-id="input-first-name"
							/>

							<CInput
								v-model.trim="lastName"
								disabled
								class="register-social-form-input"
								placeholder="Last Name"
								autocomplete="last name"
								data-test-id="input-last-name"
							/>

							<CInput
								v-model.trim="$v.email.$model"
								:is-valid="isEmailValid"
								:invalid-feedback="errorEmailText"
								class="login-form-input"
								placeholder="Email"
								autocomplete="email"
								data-test-id="input-email"
								@input="clearRegisterSocialError"
							/>

							<CInput
								v-model.trim="$v.phone.$model"
								:is-valid="isPhoneValid"
								:invalid-feedback="errorPhoneText"
								class="login-form-input"
								placeholder="Phone Number"
								autocomplete="phone number"
								data-test-id="input-phone-number"
								type="number"
								@input="clearRegisterSocialError"
							/>

							<div class="register-social-form-terms-and-condition">
								<CInputCheckbox
									:checked.sync="isTermsAndCondition"
									custom
								/>

								<label>I have read and accept the <a href="#">Privacy Policy</a> and <a href="#">Terms of Use</a> of Com7.</label>
							</div>

							<template v-if="isTermsAndConditionValid === false">
								<label class="typo-error-text">{{ errorisTermsAndConditionText }}</label>
							</template>

							<div class="text-center">
								<CButton
									type="submit"
									color="primary"
									class="submit-button px-4"
									data-test-id="button-submit"
									@click="handleRegisterSocial"
								>
									Register
								</CButton>
							</div>
						</CForm>
					</div>
				</CCol>
			</CRow>
			<p v-else>something wrong</p>
		</CContainer>

		<BaseModalConfirm
			ref="modal-confirm"
			title="Waiting for approve"
			description="Your user account has not been approved yet. Please wait for the process to complete or contact Mr. Ek in the Marketing department."
			@onConfirm="handleConfirm"
		/>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import BaseModalConfirm from '@/components/BaseModalConfirm.vue';
import { required, email, maxLength } from 'vuelidate/lib/validators';
import { pathOr } from '../assets/js/helpers';
import errorMessage from '../enums/errorMessage';
import { SOCIAL_PROVIDER_GROUP } from '../enums/social';
import { ROUTE_NAME } from '../enums/route';

export default {
	name: 'SocialRegister',
	components: {
		BaseModalConfirm,
	},
	validations: {
		email: {
			format(value) {
				return email(value);
			},
			required,
		},
		phone: {
			required,
			maxLength: maxLength(10),
		},
	},
	data() {
		return {
			staffId: null,
			firstName: null,
			lastName: null,
			email: null,
			phone: null,
			isTouch: false,
			isTermsAndCondition: false,
		};
	},
	computed: {
		...mapState({
			isLoading: (state) => state.auth.isLoading,
			errorRegisterSocial: (state) => state.auth.error,
			errorRegisterSocialMessage: (state) => state.auth.errorMessage,
		}),

		// #region setup social provider
		provider() {
			return this.$route.query.provider || null;
		},
		title() {
			return SOCIAL_PROVIDER_GROUP[this.provider].form.header.title;
		},
		desc() {
			return SOCIAL_PROVIDER_GROUP[this.provider].form.header.desc;
		},
		icon() {
			return SOCIAL_PROVIDER_GROUP[this.provider].form.header.icon;
		},
		// #endregion

		errorEmail() {
			return this.errorRegisterSocial || pathOr(false, ['$v', 'email', '$error'])(this);
		},
		errorEmailText() {
			const validateRequired = pathOr(false, ['$v', 'email', 'required'])(this);
			const validateFormat = pathOr(false, ['$v', 'email', 'format'])(this);

			if (!validateRequired) {
				return this.$t(errorMessage.REQUIRED_FIELD);
			} else if (!validateFormat) {
				return this.$t(errorMessage.INVALID_FORMAT);
			}
			return null;
		},
		isEmailValid() {
			// If error return false, if not return null
			return this.errorEmail ? false : null;
		},

		errorPhone() {
			return pathOr(false, ['$v', 'phone', '$error'])(this);
		},
		errorPhoneText() {
			const validateRequired = pathOr(false, ['$v', 'phone', 'required'])(this);
			const validateMaxLength = pathOr(false, ['$v', 'phone', 'maxLength'])(this);

			if (!validateRequired) {
				return this.$t(errorMessage.REQUIRED_FIELD);
			} else if (!validateMaxLength) {
				return this.$t(errorMessage.REGISTER_SOCIAL_MAXIMUM_LENGTH, {
					number: 10,
					field: 'Phone Number',
				});
			}
			return null;
		},
		isPhoneValid() {
			// If error return false, if not return null
			return this.errorPhone ? false : null;
		},

		errorisTermsAndCondition() {
			return this.errorRegisterSocial || (this.isTermsAndCondition === false && this.isTouch);
		},

		errorisTermsAndConditionText() {
			if (this.isTermsAndCondition === false) {
				return this.$t(errorMessage.REQUIRED_CHECKED);
			}
			return null;
		},
		isTermsAndConditionValid() {
			// If error return false, if not return null
			return this.errorisTermsAndCondition ? false : null;
		},
	},
	created() {
		// fill data
		this.staffId = pathOr(null, ['$route', 'params', 'staff_id'])(this);
		this.firstName = pathOr(null, ['$route', 'params', 'first_name'])(this);
		this.lastName = pathOr(null, ['$route', 'params', 'last_name'])(this);
		this.email = pathOr(null, ['$route', 'params', 'email'])(this);
		this.phone = pathOr(null, ['$route', 'params', 'phone_number'])(this);
	},
	methods: {
		...mapActions({
			clearRegisterSocialError: 'auth/clearRegisterSocialError',
			postSocialRegister: 'auth/postSocialRegister',
			showToast: 'toast/showToast',
		}),
		async handleRegisterSocial() {
			this.$v.$touch();
			this.isTouch = true;

			if (!this.$v.$invalid && this.isTermsAndCondition) {
				this.$refs['modal-confirm'].open();
			}
		},

		async handleConfirm() {
			try {
				await this.postSocialRegister({
					token: pathOr(null, ['$route', 'params', 'crm_token'])(this),
					payload: {
						staffId: this.staffId,
						firstName: this.firstName,
						lastName: this.lastName,
						email: this.email,
						phone: this.phone,
						isAcceptPolicy: this.isTermsAndCondition,
					},
				});

				if (this.errorRegisterSocial) {
					this.showToast({
						content: this.errorRegisterSocialMessage,
						header: 'Error',
						type: 'danger',
					});
				} else {
					this.showToast({
						content: 'Register social success',
						header: 'Success',
						type: 'success',
					});
					this.$router.push({ name: ROUTE_NAME.LOGIN });
				}
			} catch (error) {
				this.showToast({
					content: this.errorRegisterSocialMessage,
					header: 'Error',
					type: 'danger',
				});
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.register-social-form {
	width: 444px;
	max-width: 100%;
	margin: 0 auto;

	header {
		display: flex;
		align-items: center;
		flex-direction: column;
	}

	// .register-social-form-icon
	&-icon {
		width: 64px;
		height: 64px;
		margin-bottom: 16px;
	}

	// .register-social-form-heading
	&-heading {
		font-size: rem(20);
		font-weight: 600;
		line-height: rem(24);
		margin-bottom: 32px;
		margin-top: 0;
	}

	// /register-social-form-desc
	&-desc {
		font-size: rem(14);
		font-weight: 400;
		line-height: rem(20);
		margin-bottom: 24px;
		margin-top: 0;
	}

	// .register-social-form-input
	&-input {
		margin-bottom: 20px;

		// overwrite hide append icon
		::v-deep .is-invalid {
			background-image: none;
		}
	}

	// .regsiter-social-form-terms-and-condition
	&-terms-and-condition {
		width: 100%;
		height: 35px;
		margin-bottom: 24px;

		display: flex;
		align-items: baseline;
	}
}

.submit-button {
	width: 100%;
}
</style>
